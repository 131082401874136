<template lang="pug">
div
  .brand-stat-wrapper.brand-stat-wrapper-no-stats.px-0(v-if="noStats")
    .brand-stat-wrapper-no-stats-text(v-if="!hasCustomDomain")
      .brand-stat-wrapper-no-stats-text-desc(v-html="$t('noStatisticsShopify')")
      //- router-link(class="brand-btn brand-btn-sm brand-btn-insertcode brand-btn-insertcode-reverse mx-auto" :to="{name: 'new_campaign'}") {{ $t('newCampaign') }}
    .brand-stat-wrapper-no-stats-text(v-if="hasCustomDomain")
      .brand-stat-wrapper-no-stats-text-desc(v-html="$t('noStatistics')")
      //- button(class="brand-btn brand-btn-sm brand-btn-insertcode brand-btn-insertcode-reverse mx-auto" @click="$modal.show('insert-code')")
      //-   code-view-svg.brand-insertcode-svg
      //-   span {{ $t('insertCode') }}
    img(:src="require('@/assets/admin/svg/performance-placeholder.svg')")
  template(v-else)
    skeleton-loader(type="dashboard-stat" v-if="loadingTotalStatistics")
    template(v-else)
      .row.mx-0.brand-chart-tabs
        template(v-for="stat in stats")
          .brand-chart-tab(
            :class="{ 'col-12 col-sm-4': stats.length === 3, 'col-12 chart-col-sm-2_5': stats.length === 5, active: stat.type == getSelectedStatBox }"
            @click="selectedStatBox = stat.type"
          )
            chart-tab(:stat="stat")
      .px-0
        .brand-stat-wrapper.brand-stat-wrapper-no-stats.px-0(
          v-if="['assistedRevenue', 'impressionValue'].includes(getSelectedStatBox) && !selectedStat.total"
        )
          .brand-stat-wrapper-no-stats-text
            .brand-stat-wrapper-no-stats-text-desc {{ $t('notEnoughData') }}
          img(:src="require('@/assets/admin/svg/performance-placeholder.svg')")
        line-chart(
          v-else
          :chart-data="getChartData"
          :options="chartOptions"
          :height="220"
          ref="lineChart"
        )
</template>
<script>
  import moment from 'moment';
  import genFakeChart from '@/mixins/genFakeChart';
  import dateFormat from '@/mixins/dateFormat';
  import { mapGetters } from 'vuex';
  import SkeletonLoader from '@/components/SkeletonLoader/SkeletonLoader.vue';
  import ChartTab from '@/components/Charts/ChartTab.vue';
  import formatStat from '@/mixins/formatStat';

  export default {
    components: {
      LineChart: () => import('@/components/Charts/LineChart'),
      SkeletonLoader,
      ChartTab,
    },
    mixins: [dateFormat, genFakeChart, formatStat],
    props: {
      totalStatistics: {
        type: Object,
        required: true,
      },
      interval: {
        type: Object,
        required: true,
      },
      loadingTotalStatistics: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {
        selectedStatBox: undefined,
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          legend: { display: false },
          tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
              label: (tooltipItem) => {
                const statItem = {
                  total: tooltipItem.yLabel,
                  type: this.getSelectedStatBox,
                };
                return this.formatNumber({
                  value: statItem.total,
                  key: statItem.type,
                  isLongFormat: true,
                });
              },
            },
          },
          scales: {
            xAxes: [
              {
                gridLines: { display: false },
                ticks: {
                  beginAtZero: false,
                  maxTicksLimit: 10,
                  maxRotation: 0,
                  fontColor: '#BBBFC3',
                  padding: 10,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  maxTicksLimit: 6,
                  beginAtZero: true,
                  fontColor: '#BBBFC3',
                  callback: (value) => {
                    if (value % 1 === 0) {
                      return this.$options.filters.thousandSep(value);
                    }
                  },
                  padding: 20,
                },
                gridLines: {
                  drawBorder: false,
                  tickMarkLength: 0,
                },
              },
            ],
          },
          elements: {
            line: {
              tension: 0,
              borderWidth: 4,
            },
          },
        },
      };
    },

    computed: {
      ...mapGetters(['hasCustomDomain']),
      getSelectedStatBox() {
        return this.selectedStatBox || 'impressions';
      },
      noStats() {
        return !this.$store.getters.hasAnyDomainBeenRequested;
      },
      // eslint-disable-next-line
      getChartData() {
        for (const stat of this.stats) {
          if (stat.type === this.getSelectedStatBox) {
            if (stat.data.length) {
              return {
                datasets: [{ data: JSON.parse(JSON.stringify(stat.data)) }],
                labels: this.labels,
              };
            }
            return {
              datasets: [{ data: this.genFakeChartData(this.interval) }],
              labels: this.genFakeChartLabels(this.interval),
            };
          }
        }
      },
      selectedStat() {
        return this.stats.find((s) => s.type === this.getSelectedStatBox);
      },
      stats() {
        if (!this.noStats) {
          return this.totalStatistics.stats;
        }

        return [
          {
            type: 'impressions',
            total: 0,
            percentage: 0,
            data: [],
          },
          {
            type: 'conversionRate',
            total: 0,
            percentage: 0,
            data: [],
          },
          {
            type: 'conversions',
            total: 0,
            percentage: 0,
            data: [],
          },
          {
            type: 'assistedRevenue',
            total: 0,
            percentage: 0,
            data: [],
          },
          {
            type: 'impressionValue',
            total: 0,
            percentage: 0,
            data: [],
          },
        ];
      },
      labels() {
        return this.totalStatistics
          ? this.totalStatistics.labels.map((d, index) =>
              index === 0 || index === this.totalStatistics.labels.length - 1
                ? ''
                : moment(d).format(this.shortDateFormat),
            )
          : [];
      },
    },
  };
</script>
<style lang="sass">
  @import './src/sass/components/_chart.sass'
  .brand-chart-tabs
    container: sidebar / inline-size
  .chart-col-sm-2_5
    -webkit-box-flex: 0
    -ms-flex: 0 0 16.6666666667%
    flex: 0 0 16.6666666667%
    max-width: 16.6666666667%

    @container sidebar (width < 925px)
      -webkit-box-flex: 0
      -ms-flex: 0 0 20%
      flex: 0 0 20%
      max-width: 20%
    .brand-chart-tab-stat-number
      @container sidebar (width < 925px)
        font-size: 1.5rem
      @container sidebar (width < 810px)
        font-size: 1.25rem
    .brand-chart-tab-stat-percent
      @container sidebar (width < 810px)
        font-size: 0.75rem

    .brand-chart-tab-inner
      display: flex
      justify-content: space-between
      flex-direction: column
      height: 100%
</style>
