<template lang="pug">
om-table.top-campaign-table(
  :items="campaigns"
  :class="{ 'top-campaign-table-empty': empty }"
  :columns="columns"
  :columnClasses="columnClasses"
  :allItemsCount="5"
  :showHeader="true"
  :selectable="false"
  :paginate="false"
  :sortable="false"
  :loadingContent="loadingTopCampaigns"
)
  template(slot="preview-th")
    .d-flex
      .top-campaign-table-tab(:class="typeClass('top')" @click="setType('top')") {{ $t('topCampaigns') }}
      .top-campaign-table-tab(:class="typeClass('recent')" @click="setType('recent')") {{ $t('latestCampaigns') }}
  template(slot="preview" slot-scope="{ rowData }")
    router-link(:to="{ name: 'campaign_variants', params: { id: rowData.row.id } }")
      variant-preview(
        :key="rowData.row.id"
        @observable="addObservable($event.$el)"
        :allowSsr="!!rowData.row.templateType"
        @inited="updateDimensions"
        :dimensions="boxDimensions"
        :campaign="rowData.row"
        :transparentOverlay="false"
        :templateType="rowData.row.templateType"
      )
  template(slot="name" slot-scope="{ rowData }")
    .d-flex.align-items-center
      router-link(:to="{ name: 'campaign_variants', params: { id: rowData.row.id } }") {{ rowData.row.name }}
      schedule-tooltip(
        v-if="rowData.row.schedule && rowData.row.schedule.from"
        :schedule="rowData.row.schedule"
      )
    .d-flex.align-items-center
      a.cursor-pointer.campaign-domain.mr-2(:href="`http://${rowData.row.domain}`" target="_blank") {{ rowData.row.domain | removeProtocol }}
  template(slot="status" slot-scope="{ rowData }")
    toggle-button.mb-0.mt-0(
      :class="{ inactive: hasScheduleFrom(campaigns, rowData.index), 'in-experiment': rowData.row.currentExperimentName }"
      :value="rowData.row.status === 'active'"
      :key="rowData.row._id"
      :sync="true"
      :labels="{ checked: hasScheduleFrom(campaigns, rowData.index) ? $t('scheduled') : $t('active'), unchecked: $t('inactive') }"
      :style="isToggleInactive(rowData)"
      :width="88"
      :height="24"
      :margin="1"
      :ref="'toggle_' + rowData.row._id"
      @change="handleCampaignStatusChange(rowData.row, $event.value)"
    )
    UserAlertCampaignSign.ml-2(:campaign="rowData.row")
    om-tooltip(v-if="rowData.row.currentExperimentName")
      .campaign-experiment-info {{ $t('experiments.campaignPartOfExperiment', { experimentName: rowData.row.currentExperimentName }) }}
      template(slot="trigger")
        UilFlask(size="1.5rem" color="#505763")
  template(slot="device" slot-scope="{ rowData }")
    om-device.cursor-pointer(
      :type="rowData.row.device"
      @click.native="goToCampaign(rowData.row.id)"
    )
  template(slot="impressions" slot-scope="{ rowData }")
    div {{ rowData.row.impressions | thousandSep }}
  template(slot="conversions" slot-scope="{ rowData }")
    div {{ rowData.row.conversions | thousandSep }}
  template(slot="conversionRate" slot-scope="{ rowData }")
    div {{ rowData.row.conversions && rowData.row.impressions ? ((rowData.row.conversions / rowData.row.impressions) * 100).toFixed(2) + '%' : '0%' }}
  template(slot="createdAt" slot-scope="{ rowData }")
    div {{ formatDate(rowData.row.createdAt) }}
  .d-flex.justify-content-center(slot="no-result")
    om-button(
      primary
      v-if="topCampaignsResponse.inactiveCampaignCount === 0"
      :to="{ name: 'templates' }"
    ) {{ $t('createFirstCampaign') }}
    om-button(
      primary
      v-else-if="topCampaignsResponse.inactiveCampaignCount === 1"
      tag="button"
      :to="{ name: 'campaign_variants', params: { id: topCampaignsResponse.inactiveCampaignId } }"
    ) {{ $t('activateFirstCampaign') }}
    om-button(primary v-else tag="button" :to="{ name: 'campaigns' }") {{ $t('activateFirstCampaign') }}
  template(slot="loading")
    skeleton-loader(type="campaign")
</template>

<script>
  import moment from 'moment';
  import { mapGetters } from 'vuex';
  import { UilFlask } from '@iconscout/vue-unicons';
  import OmDevice from '@/components/OmDevice.vue';
  import ScheduleTooltip from '@/components/Campaign/ScheduleTooltip.vue';
  import SkeletonLoader from '@/components/SkeletonLoader/SkeletonLoader.vue';
  import VariantPreview from '@/components/Template/VariantPreview.vue';
  import UserAlertCampaignSign from '@/components/UserAlert/CampaignSign.vue';
  import dateFormat from '@/mixins/dateFormat';
  import ssrMixin from '@/mixins/ssr';
  import campaignTable from '@/mixins/campaignTable';
  import observableCollectionMixin from '@/mixins/observableCollection';

  export default {
    components: {
      OmDevice,
      SkeletonLoader,
      VariantPreview,
      UilFlask,
      ScheduleTooltip,
      UserAlertCampaignSign,
    },
    mixins: [dateFormat, campaignTable, ssrMixin, observableCollectionMixin],

    props: {
      topCampaignsResponse: { type: Object, required: true },
      loadingTopCampaigns: { type: Boolean },
    },

    data() {
      return {
        columnClasses: {
          name: 'text-left px-3 brand-campaigns-name',
          status: 'brand-campaigns-status',
          preview: 'brand-campaigns-preview',
          device: 'brand-campaigns-devices',
          impressions: 'brand-campaigns-results',
          conversions: 'brand-campaigns-results',
          conversionRate: 'brand-campaigns-results',
          createdAt: 'brand-campaigns-created',
        },
        type: 'top',
        ssrBoxSelector: '.template-preview',
      };
    },

    computed: {
      ...mapGetters(['isActiveShopifyDomain', 'brandName']),
      columns() {
        return [
          { header: '', key: 'preview' },
          { header: '', key: 'name' },
          { header: this.$t('status'), key: 'status' },
          { header: this.$t('devices'), key: 'device' },
          { header: this.$t('impressions'), key: 'impressions' },
          { header: this.$t('conversions'), key: 'conversions' },
          { header: this.$t('conversionRate'), key: 'conversionRate' },
          { header: this.$t('dateCreated'), key: 'createdAt' },
        ];
      },

      campaigns() {
        return this.topCampaignsResponse[this.type];
      },

      empty() {
        return !this.topCampaignsResponse.top.length && !this.topCampaignsResponse.recent.length;
      },
    },

    watch: {
      topCampaignsResponse: {
        handler() {
          this.type = this.typeHasCampaigns(this.type) ? this.type : 'recent';
        },
        deep: true,
      },
      campaigns: {
        handler(n) {
          if (n && n.length) {
            this.$nextTick(() => {
              this.updateDimensions();
            });
          }
        },
        deep: true,
      },
    },

    created() {
      this.moment = moment;
    },

    methods: {
      handleCampaignStatusChange(campaign, value) {
        const status = value ? 'active' : 'inactive';
        this.toggleCampaignStatus(campaign._id, status);
        this.$emit('statusChange', { campaign, status });
      },
      typeHasCampaigns(type) {
        return this.topCampaignsResponse[type].length;
      },
      typeClass(type) {
        return {
          'top-campaign-table-active-type': this.type === type,
          'top-campaign-table-disabled-type': !this.typeHasCampaigns(type),
        };
      },
      setType(type) {
        if (this.typeHasCampaigns(type)) {
          this.type = type;
        }
      },
    },
  };
</script>

<style lang="sass" scoped>
  ::v-deep .v-switch-input
    display: none
</style>
