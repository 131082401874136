<template lang="pug">
.dashboard-alert(v-if="hasText(integrationType) && hasInAnyCampaign")
  transition(name="fade")
    om-toast.mb-5.mt-2(:closable="false" color="warning" type="side")
      .alert-block-header.mb-3.align-items-center(v-html="getAlertTitle(integrationType)")
      .alert-block.mb-2
        .alert-block-wrapper.d-flex
          .alert-block-title(
            v-html="$t(`integrations.${integrationType}.reIntegration.alert.altTitle`)"
          )
          om-link.d-inline-block.alert-block-link.ml-1(
            primary
            @click="$router.push(navigateToReIntegration(integrationType))"
          ) {{ $t(`integrations.${integrationType}.reIntegration.alert.link`) }}
</template>
<script>
  import reIntegrationMixin from '../reIntegration';

  export default {
    mixins: [reIntegrationMixin],
    props: {
      integrationType: {
        type: String,
        required: true,
      },

      filteredIntegrations: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        campaignsByStatus: {},
      };
    },
    computed: {
      hasInAnyCampaign() {
        return Object.values(this.campaignsByStatus)
          .map(({ activeCnt, sum }) => activeCnt > 0 || sum > 0)
          .some((res) => res);
      },
    },
    async created() {
      const integrationIds = this.filteredIntegrations.map((i) => i._id);
      this.campaignsByStatus = await this.getCampaignsByIntegrationId(integrationIds);
    },
  };
</script>
<style lang="sass" scoped>
  @import '@/components/ReIntegration/reIntegration.sass'
</style>
