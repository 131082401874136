<template lang="pug">
om-tooltip.mx-2(placement="right")
  .schedule-tooltip
    .text-nowrap.schedule-from-to-date {{ formatDate(schedule.from, `${dateFormat} ${timeFormat}`) }} - {{ schedule.to ? formatDate(schedule.to, `${dateFormat} ${timeFormat}`) : '' }}
    template(v-if="schedule.repeatsOn && schedule.repeatsOn.length")
      .text-nowrap.schedule-days-of-week {{ getDaysOfWeek(schedule.repeatsOn[0].daysOfWeek) }}
      .text-nowrap.schedule-from-to-time {{ getFromToTime(schedule.repeatsOn[0]) }}
  template(slot="trigger")
    UilClock(
      slot="icon"
      size="1.5rem"
      :color="isScheduleExpired(schedule) ? `#ee3554` : `#2cc896`"
    )
</template>
<script>
  import { UilClock } from '@iconscout/vue-unicons';
  import campaignTable from '@/mixins/campaignTable';
  import dateFormat from '@/mixins/dateFormat';

  export default {
    components: { UilClock },
    mixins: [dateFormat, campaignTable],
    props: {
      schedule: {
        type: Object,
      },
    },
    data() {
      return {
        days: [
          this.$t('daysShort.sunday'),
          this.$t('daysShort.monday'),
          this.$t('daysShort.tuesday'),
          this.$t('daysShort.wednesday'),
          this.$t('daysShort.thursday'),
          this.$t('daysShort.friday'),
          this.$t('daysShort.saturday'),
        ],
      };
    },
    methods: {
      getDaysOfWeek(daysOfWeek) {
        const days = daysOfWeek.slice();
        if (days.length && days[0] === 0) {
          days.shift();
          days[days.length] = 0;
        }

        return days.map((day) => this.days[day]).join(', ');
      },
      getFromToTime({ fromTime, toTime }) {
        let timeString = '';

        if (fromTime) {
          timeString += `${this.$t('from')}: ${fromTime} `;
        }

        if (toTime) {
          timeString += `${this.$t('to')}: ${toTime}`;
        }

        return timeString;
      },
    },
  };
</script>
