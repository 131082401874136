<template lang="pug">
.brand-chart-tab-inner
  template(v-if="showTabTooltip")
    .d-flex.align-items-center.mb-2
      om-tooltip(transition="fade" variant="light" big :arrow="false" :placement="`top-start`")
        span {{ $t(`chartTooltips.${stat.type}`) }}
        template(slot="trigger")
          .brand-chart-tab-title {{ $t(stat.type) }}
  template(v-else)
    .brand-chart-tab-title.mb-2 {{ $t(stat.type) }}
  .brand-chart-tab-stat
    .brand-chart-tab-stat-number
      template(v-if="isUseTooltip")
        om-tooltip(transition="fade" variant="light") {{ formatNumber({ value: stat.total, key: stat.type, isLongFormat: true }) }}
          template(slot="trigger") {{ formatNumber({ value: stat.total, key: stat.type }) }}
      template(v-else) {{ formatNumber({ value: stat.total, key: stat.type, isLongFormat: true }) }}
    .brand-chart-tab-stat-percent.ml-2(
      :class="{ positive: stat.percentage > 0, negative: stat.percentage < 0 }"
    ) {{ (stat.percentage > 0 ? '+' + stat.percentage : stat.percentage) + '%' }}
</template>
<script>
  import formatStat from '@/mixins/formatStat';

  export default {
    mixins: [formatStat],
    props: {
      stat: {
        type: Object,
        default: () => {},
      },
      showTabTooltip: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      isUseTooltip() {
        return (
          (this.stat.total > 10000 && this.stat.type === `assistedRevenue`) ||
          this.stat.total > 1000000
        );
      },
    },
  };
</script>
