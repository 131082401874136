<template lang="pug">
.brand-tabs(:class="!showTabs ? 'brand-tabs-hidden-tabs' : ''")
  ul.brand-tab-list(v-if="showTabs")
    li.brand-tab-list-element(
      v-for="(tab, i) in tabs"
      :key="i"
      :class="{ 'is-active': tab.isActive }"
    )
      div(v-html="tab.header" @click.prevent="selectTab(tab.hash)")
  .brand-tabs-content
    slot
</template>

<script>
  export default {
    props: {
      showTabs: { type: Boolean, default: true },
      active: { type: Number, default: 0 },
      activeByReference: { type: String },
    },

    data() {
      return {
        tabs: [],
      };
    },

    mounted() {
      this.tabs = this.$children;
      if (this.activeByReference) {
        const tab = this.tabs.find((tab) => tab.reference === this.activeByReference);
        tab.isActive = true;
      } else {
        this.tabs[this.active].isActive = true;
      }
    },

    methods: {
      selectTab(hash) {
        let selectedIndex = 0;
        this.tabs.forEach((tab, index) => {
          if (tab.hash === hash) {
            tab.isActive = true;
            selectedIndex = index;
          } else {
            tab.isActive = false;
          }
        });
        this.$emit('selected', selectedIndex);
      },
    },
  };
</script>
