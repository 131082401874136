import runtimeConfig from '@/config/runtime';
import axios from 'axios';
import { isNewPaymentEnabled } from '@/utils/features';

import CHANGE_PACKAGE from '@/graphql/ChangePackage.gql';
import CANCEL_SUBSCRIPTION from '@/graphql/CancelSubscription.gql';
import KEEP_SUBSCRIPTION from '@/graphql/KeepSubscription.gql';
import GET_SHOPIFY_CONFIRM_URL from '@/graphql/GetShopifyConfirmUrl.gql';
import GET_SHOPIFY_CUSTOM_CONFIRM_URL from '@/graphql/GetShopifyCustomConfirmUrl.gql';
import CHECK_SHOPIFY_CHARGE_STATUS from '@/graphql/CheckShopifyChargeStatus.gql';
import GET_AFFILIATE_DATA from '@/graphql/GetAffiliateData.gql';
import GET_AFFILIATE_PAYOUT_ITEMS from '@/graphql/GetAffiliatePayoutItems.gql';
import AFFILIATE_PAYOUT from '@/graphql/AffiliatePayoutRequest.gql';

const SALES_DOMAIN = runtimeConfig.VUE_APP_SALES_DOMAIN || '';

class PaymentAdapter {
  constructor(authData, accountFeatures, apollo) {
    this.authData = authData;
    this.accountFeatures = accountFeatures;
    this.apollo = apollo;
  }

  convertOrderData(data) {
    let query = [
      `payment[lastname]=${data.firstName}`,
      `payment[firstname]=${data.lastName}`,
      `payment[country_id]=${data.country}`,
      `payment[company]=${data.billingName}`,
      `payment[city]=${data.city}`,
      `payment[address_1]=${data.address}`,
      `payment[postcode]=${data.postalCode}`,
      `payment[eu_vat_number]=${data.euVatNumber}`,
      `payment_method=${data.paymentMethod}`,
      `selected_package=${data.plan.toUpperCase()}`,
      `selected_period=${data.period}`,
      'form_type=guest',
    ];

    if (data.paymentNonce) {
      query.push(`payment_method_nonce=${data.paymentNonce}`);
    }

    query = query.map((element) => {
      return encodeURI(element);
    });

    return query.join('&');
  }

  createAddress(data) {
    return {
      firstName: data.firstName,
      lastName: data.lastName,
      countryId: data.country,
      company: data.billingName,
      city: data.city,
      address: data.address,
      postalCode: data.postalCode,
      euVatNumber: data.euVatNumber,
    };
  }

  sendCreateOrder(data) {
    if (isNewPaymentEnabled(this.accountFeatures)) {
      return { data: { success: true } };
    }

    const url = this.buildCheckoutUrl('setOrder');
    return PaymentAdapter.send(url, this.convertOrderData(data));
  }

  getSelectedPlanAndPeriod() {
    const url = this.buildCheckoutUrl('getPackageAndPeriod');

    return PaymentAdapter.get(url);
  }

  buildCheckoutUrl(resource) {
    return `${SALES_DOMAIN}/index.php?route=checkout/checkout2/${resource}&id=${this.authData.id}&hash=${this.authData.hash}`;
  }

  async sendConfirmOrder(paymentMethod, data) {
    if (isNewPaymentEnabled(this.accountFeatures)) {
      const response = await this.apollo.mutate({
        mutation: CHANGE_PACKAGE,
        variables: {
          packageName: data.plan,
          period: data.period,
          address: this.createAddress(data),
          paymentNonce: data.paymentNonce,
          method: data.paymentMethod,
          couponCode: data.couponCode,
          flexiPay: data.flexiPay ?? 0,
        },
      });

      return { data: response.data.changePackage };
    }

    const url = this.confirmUrl(paymentMethod);
    return PaymentAdapter.send(url, {});
  }

  cancelSubscription(reason) {
    if (isNewPaymentEnabled(this.accountFeatures)) {
      return this.apollo.mutate({
        mutation: CANCEL_SUBSCRIPTION,
        variables: {
          reason,
        },
      });
    }

    const url = this.braintreeUrl('cancel2');
    return PaymentAdapter.send(url, encodeURI(`reason=${reason}`));
  }

  keepSubscription(reason, solution) {
    return this.apollo.mutate({
      mutation: KEEP_SUBSCRIPTION,
      variables: {
        reason,
        solution,
      },
    });
  }

  // First req during charge creation / updating process
  async sendShopifyPay(
    plan,
    period,
    couponCode = null,
    type = 'recurring',
    replacementBehavior = 'default',
  ) {
    if (isNewPaymentEnabled(this.accountFeatures)) {
      const response = await this.apollo.query({
        query: GET_SHOPIFY_CONFIRM_URL,
        variables: {
          packageName: plan,
          period,
          type,
          replacementBehavior,
          couponCode,
        },
      });

      return { data: { ...response.data.getShopifyConfirmUrl } };
    }

    const url = this.confirmUrl('shopify');

    return PaymentAdapter.send(
      url,
      encodeURI(`selected_package=${plan.toUpperCase()}&selected_period=${period}`),
    );
  }

  // Second req during charge creation / updating process (after SY plan approving)
  async sendShopifyReturn(chargeId, type = 'recurring') {
    if (isNewPaymentEnabled(this.accountFeatures)) {
      const response = await this.apollo.query({
        query: CHECK_SHOPIFY_CHARGE_STATUS,
        variables: {
          chargeId,
          type,
        },
      });

      return { data: { ...response.data.checkShopifyChargeStatus } };
    }

    const url = `${SALES_DOMAIN}/index.php?route=payment/shopify/return2&charge_id=${chargeId}&id=${this.authData.id}&hash=${this.authData.hash}`;
    return PaymentAdapter.send(url);
  }

  async sendCustomShopifyConfirm(type) {
    if (isNewPaymentEnabled(this.accountFeatures)) {
      const response = await this.apollo.query({
        query: GET_SHOPIFY_CUSTOM_CONFIRM_URL,
        variables: {
          type,
        },
      });

      return { data: response.data.getShopifyCustomConfirmUrl };
    }
    const url = `${SALES_DOMAIN}/index.php?route=account/shopifypayment/confirm2&type=${type}&id=${this.authData.id}&hash=${this.authData.hash}`;
    return PaymentAdapter.send(url);
  }

  async sendCustomShopifyReturn(type, chargeId) {
    if (isNewPaymentEnabled(this.accountFeatures)) {
      const response = await this.apollo.query({
        query: CHECK_SHOPIFY_CHARGE_STATUS,
        variables: {
          custom: 1,
          chargeId,
          type,
        },
      });

      return { data: response.data.checkShopifyChargeStatus };
    }

    const url = `${SALES_DOMAIN}/index.php?route=account/shopifypayment/return2&charge_id=${chargeId}&type=${type}&id=${this.authData.id}&hash=${this.authData.hash}`;
    return PaymentAdapter.send(url);
  }

  sendShopifyOneTimeReturn(type, chargeId, cphash) {
    const url = `${SALES_DOMAIN}/index.php?route=account/shopifypayment/returnOneTime&charge_id=${chargeId}&type=${type}&id=${this.authData.id}&hash=${this.authData.hash}&cphash=${cphash}`;
    return PaymentAdapter.send(url);
  }

  async getAffiliateData() {
    const rawResponse = await this.apollo.query({
      query: GET_AFFILIATE_DATA,
    });

    const response = rawResponse?.data?.data;

    if (!response) {
      return { data: {} };
    }

    const main = {
      minimum_payout: response.minimumPayout,

      referred_subscribers: response.subscribers.total,
      referred_customers: response.customers.total,
      referred_visitors: response.visitors.total,
      month_referred_subscribers: response.subscribers.monthly,
      month_referred_customers: response.customers.monthly,
      month_referred_visitors: response.visitors.monthly,
      month_can_payout: response.payout.actual.canPayoutInPeriods.monthly,
      week_referred_subscribers: response.subscribers.weekly,
      week_referred_customers: response.customers.weekly,
      week_referred_visitors: response.visitors.weekly,
      week_can_payout: response.payout.actual.canPayoutInPeriods.weekly,
      year_referred_subscribers: response.subscribers.yearly,
      year_referred_customers: response.customers.yearly,
      year_referred_visitors: response.visitors.yearly,
      year_can_payout: response.payout.actual.canPayoutInPeriods.yearly,
      lastmonth_referred_subscribers: response.subscribers.lastMonth,
      lastmonth_referred_customers: response.customers.lastMonth,
      lastmonth_referred_visitors: response.visitors.lastMonth,
      lastmonth_can_payout: response.payout.actual.canPayoutInPeriods.lastMonth,
      lastyear_referred_subscribers: response.subscribers.lastYear,
      lastyear_referred_customers: response.customers.lastYear,
      lastyear_referred_visitors: response.visitors.lastYear,
      lastyear_can_payout: response.payout.actual.canPayoutInPeriods.lastYear,
      total_all_payout: response.payout.total,
      total_actual_depend: response.payout.actual.depend,
      total_actual_can_payout: response.payout.actual.canPayout,
      total_actual_do_payout: response.payout.actual.doPayout,
      total_can_payout: response.payout.actual.canPayoutInPeriods.total,
      uniqueCustomers: response.customers.uniqueCustomers,

      enable_new_payout: response.payout.enableNewPayoutForPartner,
    };
    response.main = main;

    response.partner_data = response.partnerData;
    response.commissions = [
      { ...response.commissionData, total: `${response.commissionData.total}%` },
    ];

    return { data: response };
  }

  async getAffiliatePayoutItems() {
    const rawResponse = await this.apollo.query({
      query: GET_AFFILIATE_PAYOUT_ITEMS,
    });

    const response = rawResponse?.data?.data;

    if (!response) {
      return { data: {} };
    }

    return { data: response };
  }

  async sendPayout() {
    const response = await this.apollo.mutate({
      mutation: AFFILIATE_PAYOUT,
      variables: {},
    });

    return response?.data?.affiliatePayoutRequest?.success || false;
  }

  confirmUrl(payment) {
    return `${SALES_DOMAIN}/index.php?route=payment/${payment}/confirm2&id=${this.authData.id}&hash=${this.authData.hash}`;
  }

  braintreeUrl(resource) {
    return `${SALES_DOMAIN}/index.php?route=payment/braintree${resource ? `/${resource}` : ''}&id=${
      this.authData.id
    }&hash=${this.authData.hash}`;
  }

  getInvoiceUrl(orderId) {
    return `${SALES_DOMAIN}/index.php?route=account/history/receipt2&order_id=${orderId}&id=${this.authData.id}&hash=${this.authData.hash}`;
  }

  static sendLogout() {
    const url = PaymentAdapter.accountUrl('logoutjson&jsonCallback');

    return PaymentAdapter.send(url, {});
  }

  static accountUrl(resource) {
    return `${SALES_DOMAIN}/index.php?route=account/${resource}`;
  }

  static get(url, options = {}) {
    if (!SALES_DOMAIN) {
      let data = {};
      if (url.indexOf('account/aafiliate2')) {
        data = {
          main: {
            referred_orders: 0,
            referred_subscribers: 0,
            referred_customers: 0,
            referred_visitors: 0,
            month_referred_orders: 0,
            month_referred_subscribers: 0,
            month_referred_customers: 0,
            month_referred_visitors: 0,
            week_referred_orders: 0,
            week_referred_subscribers: 0,
            week_referred_customers: 0,
            week_referred_visitors: 0,
            referred_commissions: '$ 0.00',
            month_referred_commissions: '$ 0.00',
            week_referred_commissions: '$ 0.00',
            total_all: '$ 0.00',
            total_all_payout: '$ 0.00',
            total_actual_all: '$ 0.00',
            total_actual_depend: '$ 0.00',
            total_actual_can_payout: '$ 0.00',
            total_actual_do_payout: '$ 0.00',
            payout_max_total: 0,
          },
          partner_data: {
            name: ' ',
            email: 'normalreg13@gmail.com',
            telephone: '',
            aname: ' ',
            address: 'asdasdd adasdsad ',
            city: 'New York?',
            postcode: '1232',
            code: 68219,
          },
          commissions: [
            {
              commission_id: '1',
              name: '15% os alapértelmezett jutalék',
              source_type: 'rendelés',
              total_type: 'százalékos',
              product_count: 'összes',
              auto: 'alapértelmezett',
              total: '15%',
              selected: false,
              action: [],
              front_product_list_action: '',
            },
          ],
          subscribers: null,
          tools: [
            {
              tool_id: '1',
              name: 'HU-OptiMonk affiliate link',
              type: 'link',
              url: 'http://www.optimonk.hu',
              auto: 'egyedi',
              selected: false,
              action: [],
              preview:
                '<a href="http://www.optimonk.hu/?p_id=68219" title="" target="_blank">[link]</a>',
              code: '<a href="http://www.optimonk.hu/?p_id=68219" title="" target="_blank">[link]</a>',
              link: 'http://www.optimonk.hu/?p_id=68219',
            },
          ],
        };
      }

      return new Promise((resolve) => {
        console.log(`Sales adapter debug: url:${url}`);
        resolve({ data });
      });
    }

    return axios.get(url, { withCredentials: true, ...options });
  }

  static send(url, data) {
    if (!SALES_DOMAIN) {
      return new Promise((resolve) => {
        console.log(`Payment adapter debug: url:${url}`, 'data:', data);
        resolve();
      });
    }

    return axios.post(url, data, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      withCredentials: true,
    });
  }
}

export { PaymentAdapter };
