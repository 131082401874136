<template lang="pug">
.d-flex.justify-content-center.align-items-center(
  v-if="hasAnyAlert"
  @click="onClick"
  @mouseenter="onIconHover"
)
  OmTooltip(
    :class="{ 'cursor-pointer': isFixableNamedIntegrationError(mostRelevant ? mostRelevant.type : '') }"
  )
    span {{ getAlertTitle(mostRelevant) }}
    template(#trigger)
      component(:is="icon" size="1.5rem" :color="color")
</template>

<script>
  import { UilExclamationCircle, UilExclamationTriangle } from '@iconscout/vue-unicons';
  import { track } from '@/services/xray';
  import { getAccountIdFromCookie } from '@/util';
  import { campaignAlertByTypes } from '@/utils/campaignAlerts';
  import userAlertMixin from '@/mixins/userAlert';

  const TYPE_ERROR = 'error';
  const TYPE_WARNING = 'warning';
  const COLOR_ERROR = '#E4252D';
  const COLOR_WARNING = '#F0C920';

  export default {
    props: {
      campaign: { type: Object, required: true },
    },
    mixins: [userAlertMixin],
    computed: {
      alerts() {
        return this.campaign?.alerts ?? [];
      },
      type() {
        if (this.isActiveButNoActiveVariant || this.errors.length) return TYPE_ERROR;

        return TYPE_WARNING;
      },
      isError() {
        return this.type === TYPE_ERROR;
      },
      color() {
        return this.isError ? COLOR_ERROR : COLOR_WARNING;
      },
      icon() {
        return this.isError ? UilExclamationTriangle : UilExclamationCircle;
      },
      hasAnyAlert() {
        return this.isActiveButNoActiveVariant || this.mostRelevant;
      },
      isActiveButNoActiveVariant() {
        const isActive = this.campaign.status === 'active';
        return isActive && this.campaign.variants?.every?.(({ status }) => status !== 'active');
      },
      errors() {
        return this.getAlertsByType(TYPE_ERROR);
      },
      warnings() {
        return this.getAlertsByType(TYPE_WARNING);
      },
      alertPriority() {
        return [...this.errors, ...this.warnings];
      },
      mostRelevant() {
        return this.alertPriority?.[0];
      },
    },
    watch: {
      hasAnyAlert: {
        handler(hasAlert) {
          if (hasAlert) {
            this.doTracking('show');
          }
        },
        immediate: true,
      },
    },
    methods: {
      getAlertsByType(type) {
        const items = [];
        this.alerts.forEach((element) => {
          if (campaignAlertByTypes[type].includes(element.type) || type === TYPE_WARNING) {
            items.push(element);
          }
        });
        return items;
      },
      onClick() {
        this.doTracking('click');
        this.$router.push({
          name: 'campaign_variants',
          params: {
            userId: getAccountIdFromCookie(),
            id: this.campaign.id,
          },
          query: {
            openAlert: true,
          },
        });
      },
      onIconHover() {
        this.doTracking('hover');
      },
      doTracking(type) {
        track('userAlert', {
          view: this.$route.name,
          component: 'list-icon',
          type,
          name: this.mostRelevant?.type ?? 'unknown',
        });
      },
    },
  };
</script>
