var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('om-table',{staticClass:"top-campaign-table",class:{ 'top-campaign-table-empty': _vm.empty },attrs:{"items":_vm.campaigns,"columns":_vm.columns,"columnClasses":_vm.columnClasses,"allItemsCount":5,"showHeader":true,"selectable":false,"paginate":false,"sortable":false,"loadingContent":_vm.loadingTopCampaigns},scopedSlots:_vm._u([{key:"preview",fn:function(ref){
var rowData = ref.rowData;
return [_c('router-link',{attrs:{"to":{ name: 'campaign_variants', params: { id: rowData.row.id } }}},[_c('variant-preview',{key:rowData.row.id,attrs:{"allowSsr":!!rowData.row.templateType,"dimensions":_vm.boxDimensions,"campaign":rowData.row,"transparentOverlay":false,"templateType":rowData.row.templateType},on:{"observable":function($event){return _vm.addObservable($event.$el)},"inited":_vm.updateDimensions}})],1)]}},{key:"name",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('router-link',{attrs:{"to":{ name: 'campaign_variants', params: { id: rowData.row.id } }}},[_vm._v(_vm._s(rowData.row.name))]),(rowData.row.schedule && rowData.row.schedule.from)?_c('schedule-tooltip',{attrs:{"schedule":rowData.row.schedule}}):_vm._e()],1),_c('div',{staticClass:"d-flex align-items-center"},[_c('a',{staticClass:"cursor-pointer campaign-domain mr-2",attrs:{"href":("http://" + (rowData.row.domain)),"target":"_blank"}},[_vm._v(_vm._s(_vm._f("removeProtocol")(rowData.row.domain)))])])]}},{key:"status",fn:function(ref){
var rowData = ref.rowData;
return [_c('toggle-button',{key:rowData.row._id,ref:'toggle_' + rowData.row._id,staticClass:"mb-0 mt-0",class:{ inactive: _vm.hasScheduleFrom(_vm.campaigns, rowData.index), 'in-experiment': rowData.row.currentExperimentName },style:(_vm.isToggleInactive(rowData)),attrs:{"value":rowData.row.status === 'active',"sync":true,"labels":{ checked: _vm.hasScheduleFrom(_vm.campaigns, rowData.index) ? _vm.$t('scheduled') : _vm.$t('active'), unchecked: _vm.$t('inactive') },"width":88,"height":24,"margin":1},on:{"change":function($event){return _vm.handleCampaignStatusChange(rowData.row, $event.value)}}}),_c('UserAlertCampaignSign',{staticClass:"ml-2",attrs:{"campaign":rowData.row}}),(rowData.row.currentExperimentName)?_c('om-tooltip',[_c('div',{staticClass:"campaign-experiment-info"},[_vm._v(_vm._s(_vm.$t('experiments.campaignPartOfExperiment', { experimentName: rowData.row.currentExperimentName })))]),_c('template',{slot:"trigger"},[_c('UilFlask',{attrs:{"size":"1.5rem","color":"#505763"}})],1)],2):_vm._e()]}},{key:"device",fn:function(ref){
var rowData = ref.rowData;
return [_c('om-device',{staticClass:"cursor-pointer",attrs:{"type":rowData.row.device},nativeOn:{"click":function($event){return _vm.goToCampaign(rowData.row.id)}}})]}},{key:"impressions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',[_vm._v(_vm._s(_vm._f("thousandSep")(rowData.row.impressions)))])]}},{key:"conversions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',[_vm._v(_vm._s(_vm._f("thousandSep")(rowData.row.conversions)))])]}},{key:"conversionRate",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',[_vm._v(_vm._s(rowData.row.conversions && rowData.row.impressions ? ((rowData.row.conversions / rowData.row.impressions) * 100).toFixed(2) + '%' : '0%'))])]}},{key:"createdAt",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',[_vm._v(_vm._s(_vm.formatDate(rowData.row.createdAt)))])]}}])},[_c('template',{slot:"preview-th"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"top-campaign-table-tab",class:_vm.typeClass('top'),on:{"click":function($event){return _vm.setType('top')}}},[_vm._v(_vm._s(_vm.$t('topCampaigns')))]),_c('div',{staticClass:"top-campaign-table-tab",class:_vm.typeClass('recent'),on:{"click":function($event){return _vm.setType('recent')}}},[_vm._v(_vm._s(_vm.$t('latestCampaigns')))])])]),_c('div',{staticClass:"d-flex justify-content-center",attrs:{"slot":"no-result"},slot:"no-result"},[(_vm.topCampaignsResponse.inactiveCampaignCount === 0)?_c('om-button',{attrs:{"primary":"","to":{ name: 'templates' }}},[_vm._v(_vm._s(_vm.$t('createFirstCampaign')))]):(_vm.topCampaignsResponse.inactiveCampaignCount === 1)?_c('om-button',{attrs:{"primary":"","tag":"button","to":{ name: 'campaign_variants', params: { id: _vm.topCampaignsResponse.inactiveCampaignId } }}},[_vm._v(_vm._s(_vm.$t('activateFirstCampaign')))]):_c('om-button',{attrs:{"primary":"","tag":"button","to":{ name: 'campaigns' }}},[_vm._v(_vm._s(_vm.$t('activateFirstCampaign')))])],1),_c('template',{slot:"loading"},[_c('skeleton-loader',{attrs:{"type":"campaign"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }