export default {
  joomla: {
    en: 'https://support.optimonk.com/hc/en-us/articles/360000011499-Integrate-with-Joomla',
    hu: 'https://support.optimonk.hu/hc/hu/articles/201733842-Joomla-',
  },
  drupal: {
    en: 'https://support.optimonk.com/hc/en-us/articles/360000011519-Integrate-with-Drupal',
    hu: 'https://support.optimonk.hu/hc/hu/articles/360003087552-Drupal',
  },
  squarespace: {
    en: 'https://support.optimonk.com/hc/en-us/articles/360000009540-Integrate-with-Squarespace',
    hu: 'https://support.optimonk.hu/hc/hu/articles/360003108631-Squarespace',
  },
  bigcommerce: {
    en: 'https://support.optimonk.com/hc/en-us/articles/206132911-Integrate-with-Bigcommerce-',
    hu: 'https://support.optimonk.hu/hc/hu/articles/360003108851-Bigcommerce-',
  },
  volusion: {
    en: 'https://support.optimonk.com/hc/en-us/articles/360000009560-Integrate-with-Volusion',
    hu: 'https://support.optimonk.hu/hc/hu/articles/360003109071-Volusion',
  },
  weebly: {
    en: 'https://support.optimonk.com/hc/en-us/articles/360000011539-Integrate-with-Weebly',
    hu: 'https://support.optimonk.hu/hc/hu/articles/360003088372-Weebly',
  },
  opencart: {
    en: 'https://support.optimonk.com/hc/en-us/articles/360000009580-Integrate-with-Opencart',
    hu: 'https://support.optimonk.hu/hc/hu/articles/201856901-OpenCart',
  },
  threedcart: {
    en: 'https://support.optimonk.com/hc/en-us/articles/360000009660-Integrate-with-3dcart',
    hu: 'https://support.optimonk.hu/hc/hu/articles/360003109191-3dcart',
  },
  oscommerce: {
    en: 'https://support.optimonk.com/hc/en-us/articles/360000011579-Integrate-with-osCommerce',
    hu: 'https://support.optimonk.hu/hc/hu/articles/201852791-osCommerce-',
  },
  corecommerce: {
    en: 'https://support.optimonk.com/hc/en-us/articles/360000009680-Integrate-with-CoreCommerce',
    hu: 'https://support.optimonk.hu/hc/hu/articles/360003109271-CoreCommerce',
  },
};
