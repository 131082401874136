<template lang="pug">
div
  .brand-wrapper.om-dashboard
    .container-fluid.position-relative
      om-heading.mb-0(h1) {{ $t('welcomeUser', firstName) }}!
      DashBoardWizardAccordion.mb-6(@initiated="showAdminLoader(false)")
      affiliate-summary.mt-6.mb-6(v-if="showAffiliateSummary" :data="affiliate" showRedirectLink)
        template(slot="title")
          h3.mb-0 {{ $t('ambassadorSummary') }}
      .pb-6.d-flex.justify-content-between
        .dashboard__chart
          .d-flex.mb-5.justify-content-between.w-full.flex-wrap
            .align-self-center.mb-4(:class="col12")
              .brand-under-heading-text
                .re-integration-alert(v-for="deprecatedIntegration in savedDeprecatedIntegrations")
                  re-integration-alert.mb-3(
                    :integrationType="deprecatedIntegration"
                    :filteredIntegrations="filteredIntegrations(deprecatedIntegration)"
                  )
            .d-flex.form-group.mb-0.justify-content-end(
              v-if="!loadingTotalStatistics && isOnboardingCompleted"
              :class="col12"
            )
              dashboard-stat-interval-popper(v-if="haveStats" :interval.sync="interval")
          dashboard-stat-chart.w-100.dashboard__chart__container(
            :onboardingFinished="isOnboardingCompleted"
            :interval="interval"
            :totalStatistics="totalStatistics"
            :loadingTotalStatistics="loadingTotalStatistics"
          )
        .dashboard__chart__recomender
          .d-flex
            .px-6.h-fit.dashboard__chart__recommender__separator
              line-separator(color="gray")
            feature-recommender(
              :onboardingFinished="isOnboardingCompleted"
              :isLoading="isAiEarlyAccessLoading"
              @earlyAccess="saveAiEarlyAccessRequest($event)"
            )
      .d-flex.promo-banners
        .teammate-invite-banner.w-100(v-if="!hasSeenTeamInviteBanner")
          om-select-card.w-100(:arrow="false" backgroundOff small)
            template(#title)
              .brand-select-card-title {{ $t('promoBanners.teammateInvite.title') }}
            template(#details)
              .brand-select-card-details {{ $t('promoBanners.teammateInvite.description') }}
            template(#rightContent)
              .d-flex
                om-button.banner-btn.ml-3(
                  secondary
                  @click="$router.push({ name: 'account_sharing' })"
                ) {{ $t('promoBanners.teammateInvite.button') }}
                close-icon.ml-4(
                  :width="10"
                  :height="10"
                  @click.native="closeBanner('invite-team')"
                )
            template(#iconContent)
              img(:src="require(`@/assets/admin/svg/icons/invite-teammate.svg`)")
        .agency-program-promo.w-100(
          v-if="isAccountFlaggedAsAgency && !hasSeenAgencyPromoBanner"
          :class="{ 'ml-3': !hasSeenTeamInviteBanner }"
        )
          om-select-card.w-100(:arrow="false" backgroundOff small)
            template(#title)
              .brand-select-card-title {{ $t('promoBanners.agencyPromo.title') }}
            template(#details)
              .brand-select-card-details {{ $t('promoBanners.agencyPromo.description') }}
            template(#rightContent)
              .d-flex
                om-button.banner-btn.ml-3(secondary @click="openAgencyProgramDesc()") {{ $t('promoBanners.agencyPromo.button') }}
                close-icon.ml-3(
                  :width="10"
                  :height="10"
                  @click.native="closeBanner('agency-promo')"
                )
            template(#iconContent)
              img(:src="require(`@/assets/admin/svg/icons/agency-promo.svg`)")
      .row.mt-5
        .col-8.align-self-center
          om-heading.mb-0(h3) {{ $t('campaigns') }}
        .col-4.d-flex.justify-content-end
          om-button(
            secondary
            :to="{ name: 'campaigns' }"
            tag="button"
            tracking="dashboard_all-campaigns"
          ) {{ $t('allCampaigns') }}
          om-button.ml-3(
            primary
            :to="routeCampaignCreate"
            tag="button"
            tracking="dashboard_new-campaigns"
          ) {{ $t('newCampaign') }}
      top-campaign-table(
        :topCampaignsResponse="topCampaignsResponse"
        :loadingTopCampaigns="loadingTopCampaigns"
        @statusChange="handleStatusChange"
      )
      need-help.mt-6.mb-3(v-if="!isSubUser")
    NewCampaign
    early-access-subscription-success
</template>

<script>
  import moment from 'moment';
  import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

  import GET_TOTAL_STATISTICS from '@/graphql/GetTotalStatistics.gql';
  import GET_ACCOUNT_SETTINGS from '@/graphql/GetAccountSettings.gql';

  import AlertBar from '@/components/AlertBar.vue';
  import TopCampaignTable from '@/components/TopCampaignTable.vue';
  import OmTabs from '@/components/OmTabs.vue';
  import OmTab from '@/components/OmTab.vue';
  import NeedHelp from '@/components/NeedHelp.vue';
  import DashboardStatChart from '@/components/Charts/DashboardStat.vue';
  import DashboardStatIntervalPopper from '@/components/Poppers/DashboardStatInterval.vue';
  import AffiliateSummary from '@/components/Affiliate/AffiliateSummary';
  import NewCampaign from '@/components/Modals/NewCampaign.vue';

  import _integrationLinks from '@/config/integrationLinks';
  import _insertCodes from '@/config/insertCodes';

  import templateCreate from '@/mixins/templateCreate';
  import palette from '@/mixins/palette';
  import shopifyAppExtension from '@/mixins/shopifyAppExtension';
  import recommenderMixin from '@/mixins/dashboard/recommender';
  import reIntegrationMixin from '@/components/ReIntegration/reIntegration';
  import ReIntegrationAlert from '@/components/ReIntegration/Alerts/Dashboard.vue';
  import Highlight from '@/components/Highlight.vue';
  import EmbeddedCampaign from '@/components/EmbeddedCampaign.vue';
  import campaignCreateMixin from '@/mixins/newCampaignFlow/campaignCreate';
  import affiliateDataMixin from '@/mixins/affiliate-data';
  import { lsStore, lsRetrieve } from '@/helpers/localStorage';
  import CHECK_COUNTDOWN from '@/graphql/CheckCountdown.gql';

  export default {
    components: {
      AlertBar,
      TopCampaignTable,
      OmTabs,
      OmTab,
      NeedHelp,
      DashboardStatChart,
      DashboardStatIntervalPopper,
      AffiliateSummary,
      NewCampaign,
      ReIntegrationAlert,
      Highlight,
      EmbeddedCampaign,
      FeatureRecommender: () => import('@/components/Onboarding/FeatureRecommender.vue'),
      LineSeparator: () => import('@/components/LineSeparator.vue'),
      OnboardingDashboardSection: () => import('@/components/Onboarding/DashboardSection.vue'),
      EarlyAccessSubscriptionSuccess: () =>
        import('@/components/Modals/EarlyAccessSubscriptionSuccess.vue'),
      DashBoardWizardAccordion: () =>
        import('@/components/Onboarding/DashBoardWizardAccordion.vue'),
    },

    mixins: [
      templateCreate,
      palette,
      shopifyAppExtension,
      reIntegrationMixin,
      recommenderMixin,
      campaignCreateMixin,
      affiliateDataMixin,
    ],

    beforeRouteEnter(to, from, next) {
      next((vm) => {
        if (from.name === 'agency_dashboard') {
          vm.fetchInsertCodeStatus();
          vm.fetchAccount();
        }
      });
    },

    data() {
      return {
        loadingTopCampaigns: true,
        topCampaignsResponse: {
          top: [],
          recent: [],
          inactiveCampaignCount: 1,
          inactiveCampaignId: null,
        },
        totalStatistics: {
          stats: [],
          labels: [],
        },
        userId: null,
        interval: {
          from: moment().subtract(6, 'days').startOf('day'),
          to: moment(),
        },
        integrationLinks: _integrationLinks,
        accountSettings: {},
        insertCodes: _insertCodes,

        fadeOutWelcomeMessage: false,

        totalImpressions: 0,
        HAS_CLOSED_BANNER_KEY: 'hasClosedBanner',
        hasSeenBanners: [],
      };
    },

    computed: {
      ...mapState(['isCodeInserted', 'device']),
      ...mapGetters([
        'hasAnyDomainBeenRequested',
        'hasOnlyShopifyDomain',
        'hasCustomDomain',
        'isSubUser',
        'interestedInAiFeatures',
        'totalConversions',
        'isAffiliate',
        'userRole',
        'domains',
        'integrations',
        'isAccountFlaggedAsAgency',
        'getLocale',
      ]),
      loadingTotalStatistics() {
        return this.$apollo.queries.totalStatistics.loading;
      },
      col12() {
        return { 'col-12': this.savedDeprecatedIntegrations?.length };
      },
      noStats() {
        return !this.hasAnyDomainBeenRequested;
      },
      haveStats() {
        return this.totalStatistics && this.totalStatistics.stats.length > 0;
      },
      showWelcomeMessage() {
        return this.$store.state.account.settings.onboarding.showWelcomeMessage;
      },
      firstName() {
        return { firstName: this.$store.state.account.login.firstName };
      },
      campaignCount() {
        const { top, recent } = this.topCampaignsResponse;
        return top.length + recent.length;
      },
      showAffiliateSummary() {
        return this.isAffiliate && this.userRole === 'owner';
      },
      accountId() {
        return this.$route.params.userId;
      },
      hasSeenTeamInviteBanner() {
        return this.hasSeenBanners?.includes('invite-team');
      },
      hasSeenAgencyPromoBanner() {
        return this.hasSeenBanners?.includes('agency-promo');
      },
    },

    watch: {
      device(n) {
        if (n === 'mobile') return this.$router.replace({ name: 'templates' });
      },
    },
    async created() {
      this.showAdminLoader(true);
      const isMobile = this.device === 'mobile';

      if (isMobile) {
        return this.$router.replace({ name: 'templates' });
      }
      this.fetchTopCampaigns();
    },

    async mounted() {
      window.scroll(0, 0);
      if (this.$route.query.scrollTo && this.$route.query.scrollTo === 'insertCode') {
        setTimeout(() => {
          this.$scrollTo('#insertCodeTabs', 0, { offset: -240 });
          this.$router.replace({ query: {} });
        }, 200);
      }

      this.$nextTick(() => {
        if (this.isCodeInserted && this.noStats) {
          this.$apollo.queries.totalStatistics.refetch();
        }
      });

      if (this.showAffiliateSummary) {
        await this.setAffiliateData();
        this.setPayoutItems();
      }
      this.getHasSeenBanner();
    },

    methods: {
      ...mapActions(['fetchInsertCodeStatus', 'fetchAccount']),
      ...mapMutations(['showAdminLoader']),
      statClass(value) {
        return value > 0 ? 'brand-color-positive' : 'brand-color-negative';
      },
      async fetchTopCampaigns() {
        const topCampaignsData = (
          await this.$axios.get(`/auth-campaign/top-campaigns`, {
            params: {
              interval: {
                from: this.interval.from.format('YYYY-MM-DD'),
                to: this.interval.to.format('YYYY-MM-DD'),
              },
            },
          })
        ).data;

        const { top, recent, inactiveCampaignCount, inactiveCampaignId } = topCampaignsData;

        this.topCampaignsResponse = {
          top: JSON.parse(JSON.stringify(top)),
          recent: JSON.parse(JSON.stringify(recent)),
          inactiveCampaignCount,
          inactiveCampaignId,
        };
        this.loadingTopCampaigns = false;
      },
      async handleStatusChange({ campaign, status }) {
        if (status === 'active') {
          this.$apollo
            .mutate({
              mutation: CHECK_COUNTDOWN,
              variables: {
                campaignId: campaign.id,
              },
            })
            .then(
              ({
                data: {
                  checkCountdown: { countdownInFuture },
                },
              }) => {
                if (countdownInFuture) {
                  this.fetchTopCampaigns();
                }
              },
            );
        }
      },
      filteredIntegrations(integrationType) {
        return this.integrations.filter((integration) => integration.type === integrationType);
      },
      closeBanner(type) {
        let hasClosedBanners = lsRetrieve(this.HAS_CLOSED_BANNER_KEY);

        if (hasClosedBanners?.[this.accountId]) {
          hasClosedBanners[this.accountId].push(type);
        } else {
          hasClosedBanners = {
            ...hasClosedBanners,
            [this.accountId]: [type],
          };
        }
        this.hasSeenBanners.push(type);
        lsStore(this.HAS_CLOSED_BANNER_KEY, hasClosedBanners);
      },
      getHasSeenBanner() {
        this.hasSeenBanners = lsRetrieve(this.HAS_CLOSED_BANNER_KEY)?.[this.accountId] || [];
      },
      openAgencyProgramDesc() {
        const link =
          this.getLocale === 'en'
            ? 'https://www.optimonk.com/partners-program/'
            : 'https://landing.optimonk.hu/legyel-a-partnerunk/';

        window.open(link, '_blank');
      },
    },

    apollo: {
      totalStatistics: {
        query: GET_TOTAL_STATISTICS,
        variables() {
          return {
            interval: {
              from: this.interval.from.format('YYYY-MM-DD'),
              to: this.interval.to.format('YYYY-MM-DD'),
            },
          };
        },
        manual: true,
        result({ data: { totalStatistics }, loading }) {
          if (!loading) {
            this.totalStatistics = totalStatistics;
          }
        },
      },
      accountSettings: {
        query: GET_ACCOUNT_SETTINGS,
      },
    },
  };
</script>
<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  .teammate-invite-banner,
  .agency-program-promo
    .brand-select-card:hover
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07)
      cursor: default
    .widget-template-card-close-icon
      svg
        height: 40px
        width: 16px

        &:hover
          cursor: pointer
    button.banner-btn
      border-color: $om-orange
      color: $om-orange

      &:hover
        color: #f17e58
        border-color: #f17e58
        background: #fff

      &:not(:disabled):not(.disabled):active
        color: #d14212
        border-color: #d14212
        background: #fff

  .dashboard
    &__chart
      width: 100%
      max-width: calc(100% - 22rem)

      &__container
        height: calc(100% - 55px)

        .brand-stat-wrapper
          height: 100%
          padding: 0

          img
            height: 100%
            object-fit: cover

      &__recommender
        align-self: end

        &__separator
          max-height: 28.25rem
          height: 100%

          &--small
            max-height: 18rem !important
</style>
