import { LIBRARY_STORE_KEY } from '@/config/constants';
import localStorage from './localStorage';

export default {
  mixins: [localStorage],
  created() {
    this.trySaveQueryData();
  },
  methods: {
    getQueryData() {
      const { templateId, color, theme } = this.$route.query;

      if (templateId) {
        return { templateId, color, theme };
      }

      return false;
    },
    getLSData() {
      const data = this.lsRetrieve(LIBRARY_STORE_KEY);

      if (data && data.templateId) {
        return data;
      }

      return false;
    },
    trySaveQueryData() {
      const qd = this.getQueryData();

      if (qd) {
        this.lsStore(LIBRARY_STORE_KEY, qd);
      }
    },
    removeLSData() {
      this.lsRemove(LIBRARY_STORE_KEY);
    },
  },
};
