<template lang="pug">
.om-embedded-campaign.w-100.my-2(:data-campaign-id="campaignId")
</template>
<script>
  export default {
    name: 'EmbeddedCampaign',
    props: {
      campaignId: {
        required: true,
        type: Number,
      },
    },
  };
</script>
