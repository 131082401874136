<template lang="pug">
om-modal(
  :name="name"
  modalClasses="affiliate-modal"
  width="750"
  color="light"
  @beforeOpen="load"
  @closeClicked="close"
  :headerBorder="true"
)
  template(#header-title)
    .row
      .col
        .title {{ $t('affiliate.modal.subscribers.title') }}
  template(#modal-body)
    om-table(
      :items="items"
      :columns="columns"
      :selectable="false"
      :loadingContent="loading"
      :sorting="sort"
      @sortingChange="sortChange"
    )
</template>

<script>
  import dateFormat from '@/mixins/dateFormat';

  import GET_AFFILIATE_SUBSCRIBERS from '@/graphql/GetAffiliateSubscribers.gql';

  export default {
    mixins: [dateFormat],
    data: () => ({
      name: 'affiliate-subscribers',
      subscribers: [],
      sort: {
        field: 'dateCreated',
        direction: -1,
      },
      loading: true,
    }),

    computed: {
      columns() {
        return [
          { header: this.$t('email'), sortable: true, key: 'email' },
          { header: this.$t('dateCreated'), sortable: true, key: 'dateCreated' },
        ];
      },
      items() {
        return this.subscribers;
      },
    },
    methods: {
      sortChange($event) {
        this.subscribers.sort((a, b) => {
          const result =
            $event.direction === 1
              ? a[$event.field] > b[$event.field]
              : a[$event.field] < b[$event.field];
          return result ? 1 : -1;
        });
      },
      async load() {
        this.loading = true;
        try {
          const response = await this.$apollo.query({
            query: GET_AFFILIATE_SUBSCRIBERS,
          });

          this.subscribers =
            response?.data?.subscribers.map((subscriber) => {
              return {
                email: subscriber.email,
                dateCreated: this.formatDate(subscriber.dateCreated, 'YYYY. MM. DD. hh:mm a'),
              };
            }) || [];
        } catch (e) {
          console.error('Error getting subscribers', e.message);
        }
        this.loading = false;
      },
      close() {
        this.$modal.hide(this.name);
      },
    },
  };
</script>
