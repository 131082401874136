<template lang="pug">
div
  .row.align-items-center.mb-4
    .col
      slot(name="title")
    .col
      .d-flex.align-items-center.justify-content-end
        .d-flex.justify-content-end.align-items-center.mr-4.font-weight-bold(
          v-if="showRedirectLink"
        )
          router-link(:to="{ name: 'ambassador_dashboard' }") {{ $t('visitAmbassadorDashboard') }}
        om-select#affiliateInterval.w-8(v-model="selectedInterval" :options="intervalOptions")
  .row.brand-affiliate-summary
    .col-12.col-sm-6.col-lg-3.mb-4
      om-info-card(
        :label="$t('affiliate.visitors')"
        :value="visitors"
        :total="totalVisitors"
        :withBackground="false"
        icon="eye"
      )
    .col-12.col-sm-6.col-lg-3.mb-4.cursor-pointer(@click="showSubscribersModal")
      om-info-card(
        :label="$t('affiliate.subscriber')"
        :value="subscribers"
        :total="totalSubscribers"
        :clickable="true"
        :withBackground="false"
        icon="trending-up"
      )
    .col-12.col-sm-6.col-lg-3.mb-4.cursor-pointer(@click="showCustomersModal")
      om-info-card(
        :label="$t('affiliate.customers')"
        :value="customers"
        :total="totalCustomers"
        :clickable="true"
        :withBackground="false"
        icon="users"
      )
    .col-12.col-sm-6.col-lg-3.mb-4.cursor-pointer(@click="showCommissionsModal")
      popper(
        trigger="hover"
        :options="{ placement: 'bottom' }"
        transition="fade"
        enter-active-class="fade-enter-active"
        leave-active-class="fade-leave-active"
      )
        .popper.brand-tooltip.brand-tooltip-light.ml-n1(style="margin-bottom: -4px") {{ $t('affiliate.netAmount') }}
        .popper-content(slot="reference")
          om-info-card(
            :label="$t('affiliate.commissions')"
            :value="commissions"
            :total="totalCommissions"
            :clickable="true"
            :withBackground="false"
            icon="dollar-sign"
            :valueClass="commissions && parseInt(commissions.substring(1)) > 0 ? 'brand-color-positive' : ''"
          )
  affiliate-subscribers
  affiliate-commissions
  affiliate-customers
</template>

<script>
  import AffiliateSubscribers from '@/components/Modals/Affiliate/Subscribers.vue';
  import AffiliateCommissions from '@/components/Modals/Affiliate/Commissions.vue';
  import AffiliateCustomers from '@/components/Modals/Affiliate/Customers.vue';

  export default {
    components: { AffiliateSubscribers, AffiliateCommissions, AffiliateCustomers },

    props: {
      data: {
        type: Object,
        default: null,
      },
      showRedirectLink: {
        type: Boolean,
        default: false,
      },
      payoutItems: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        selectedInterval: { key: 'month' },
        intervalOptions: [
          { key: 'week', value: this.$t('affiliate.thisWeek') },
          { key: 'month', value: this.$t('affiliate.thisMonth') },
          { key: 'year', value: this.$t('affiliate.thisYear') },
          { key: 'lastmonth', value: this.$t('affiliate.lastMonth') },
          { key: 'lastyear', value: this.$t('affiliate.lastYear') },
        ],
      };
    },

    computed: {
      interval() {
        return this.selectedInterval.key;
      },
      main() {
        return this.data?.main || {};
      },
      visitors() {
        return this.main[`${this.interval}_referred_visitors`];
      },
      totalVisitors() {
        return this.$options.filters.thousandSep(this.main.referred_visitors);
      },
      subscribers() {
        return this.main[`${this.interval}_referred_subscribers`];
      },
      totalSubscribers() {
        return this.$options.filters.thousandSep(this.main.referred_subscribers);
      },
      customers() {
        return this.main[`${this.interval}_referred_customers`];
      },
      totalCustomers() {
        return this.$options.filters.thousandSep(this.main.referred_customers);
      },
      commissions() {
        return this.main[`${this.interval}_can_payout`];
      },
      totalCommissions() {
        return this.main.total_can_payout;
      },
    },

    methods: {
      showSubscribersModal() {
        this.$modal.show('affiliate-subscribers');
      },
      showCommissionsModal() {
        this.$modal.show('affiliate-commissions', { payoutItems: this.payoutItems });
      },
      showCustomersModal() {
        this.$modal.show('affiliate-customers', { customers: this.main.uniqueCustomers });
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/variables/_colors.sass'

  .affiliate-modal
    top: unset
    .title
      font-size: 18px
      font-weight: 700
    .brand-modal-body
      max-height: 70vh
      padding: 1.5rem 2.5rem
      overflow-y: auto
      overflow-x: hidden
      scrollbar-color: white
      &::-webkit-scrollbar
        height: 5px
        width: 12px
      &::-webkit-scrollbar-track
        background: white
        border-radius: 4px
      &::-webkit-scrollbar-thumb
        background: #D5D8DD
        width: 4px
        border: 2px solid white
        border-radius: 8px
    .brand-table .thead
      margin: 0 !important
    .brand-table .thead .tr .th span
      padding: 0 !important
    .brand-table .thead .tr .th
      padding-bottom: 10px
      display: flex
      justify-content: flex-start
    .brand-table .tbody .tr .td
      font-weight: normal
      text-align: left
    .brand-table .tbody .tr
      min-height: auto
</style>
