<template lang="pug">
om-modal(
  :name="name"
  modalClasses="affiliate-modal"
  width="750"
  color="light"
  @beforeOpen="beforeOpen"
  @closeClicked="close"
  :headerBorder="true"
)
  template(#header-title)
    .row
      .col
        .title {{ $t('affiliate.customers') }}
  template(#modal-body)
    om-table(
      :items="customers"
      :columns="columns"
      :selectable="false"
      :sorting="sort"
      @sortingChange="sortChange"
    )
</template>

<script>
  import dateFormat from '@/mixins/dateFormat';

  export default {
    mixins: [dateFormat],
    data: () => ({
      name: 'affiliate-customers',
      customers: [],
      sort: {
        field: 'firstSubscriptionDate',
        direction: -1,
      },
    }),

    computed: {
      columns() {
        return [
          { header: this.$t('email'), sortable: true, key: 'email' },
          {
            header: this.$t('affiliate.firstSubscriptionDate'),
            sortable: true,
            key: 'firstSubscriptionDate',
          },
        ];
      },
    },
    methods: {
      beforeOpen({ params }) {
        this.customers = params.customers.map((customer) => ({
          ...customer,
          firstSubscriptionDate: this.formatDate(
            customer.firstSubscriptionDate,
            'YYYY. MM. DD. hh:mm a',
          ),
        }));
      },
      sortChange($event) {
        this.customers.sort((a, b) => {
          const result =
            $event.direction === 1
              ? a[$event.field] > b[$event.field]
              : a[$event.field] < b[$event.field];
          return result ? 1 : -1;
        });
      },
      close() {
        this.$modal.hide(this.name);
      },
    },
  };
</script>
