<template lang="pug">
om-modal(
  :name="name"
  modalClasses="affiliate-modal"
  width="950"
  color="light"
  @beforeOpen="beforeOpen"
  @closeClicked="close"
  :headerBorder="true"
)
  template(#header-title)
    .row
      .col
        .title {{ $t('affiliate.commissions') }}
  template(#modal-body)
    affiliate-item-table(
      :items="commissions"
      :sortable="true"
      customDateFormat="YYYY. MM. DD. hh:mm a"
    )
</template>

<script>
  import AffiliateItemTable from '@/components/Tables/AffiliateItemTable.vue';

  export default {
    components: { AffiliateItemTable },
    data: () => ({
      name: 'affiliate-commissions',
      commissions: [],
    }),
    methods: {
      beforeOpen({ params }) {
        this.commissions = params.payoutItems;
      },
      close() {
        this.$modal.hide(this.name);
      },
    },
  };
</script>
