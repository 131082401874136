<template lang="pug">
popper(trigger="click" :options="{ placement: 'bottom' }" @created="popoverInstance = $event")
  .popper.brand-popover.brand-select-popover
    button.brand-btn.brand-btn-select-popover(type="button" @click="changeIntervalByDays(6)") {{ $t('lastWeek') }}
    button.brand-btn.brand-btn-select-popover(type="button" @click="changeIntervalByDays(30)") {{ $t('lastMonth') }}
    button.brand-btn.brand-btn-select-popover(type="button" @click="setIntervalToThisMonth()") {{ $t('thisMonth') }}
    button.brand-btn.brand-btn-select-popover(type="button" @click="setIntervalToPreviousMonth()") {{ $t('previousMonth') }}
    v-date-picker(
      mode="range"
      :value="vCalendarFormat"
      @input="changeIntervalByDates"
      :formats="calendarFormats"
      :max-date="new Date()"
      show-caps
    )
      button.brand-btn.brand-btn-select-popover(type="button") {{ $t('customPeriod') }}
  div(slot="reference")
    .brand-select-box.cursor-pointer {{ intervalFormat.from }} - {{ intervalFormat.to }}
      i.fa.fa-angle-down(aria-hidden="true")
</template>
<script>
  import moment from 'moment';

  import dateFormat from '@/mixins/dateFormat';

  export default {
    mixins: [dateFormat],

    props: {
      interval: {
        type: Object,
      },
    },

    data() {
      return {
        popoverInstance: null,
      };
    },

    computed: {
      intervalFormat() {
        return {
          from: this.interval.from.format(this.dateFormat),
          to: this.interval.to.format(this.dateFormat),
        };
      },
      vCalendarFormat() {
        return {
          start: this.interval.from.toDate(),
          end: this.interval.to.toDate(),
        };
      },
      calendarFormats() {
        return {
          title: 'MMMM YYYY',
          weekdays: 'W',
          navMonths: 'MMM',
          input: [this.shortDateFormat],
          dayPopover: this.shortDateFormat,
        };
      },
    },

    methods: {
      changeIntervalByDays(interval) {
        this.popoverInstance.doClose();
        this.$emit('update:interval', {
          from: moment().subtract(interval, 'days').startOf('day'),
          to: moment(),
        });
      },
      changeIntervalByDates(selectedDates) {
        if (selectedDates && !moment(selectedDates.start).isSame(selectedDates.end)) {
          this.$emit('update:interval', {
            from: moment(selectedDates.start),
            to: moment(selectedDates.end),
          });
          this.popoverInstance.doClose();
        }
      },
      setIntervalToThisMonth() {
        this.popoverInstance.doClose();
        this.$emit('update:interval', {
          from: moment().startOf('month'),
          to: moment(),
        });
      },
      setIntervalToPreviousMonth() {
        this.popoverInstance.doClose();
        this.$emit('update:interval', {
          from: moment().subtract(1, 'month').startOf('month'),
          to: moment().subtract(1, 'month').endOf('month'),
        });
      },
    },
  };
</script>
