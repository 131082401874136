<template lang="pug">
section.brand-tab(v-show="isActive" :aria-hidden="!isActive" :id="hash")
  slot
</template>

<script>
  export default {
    props: {
      name: { required: true },
      reference: { type: String },
      htmlContent: { default: '' },
    },

    data() {
      return {
        isActive: false,
      };
    },

    computed: {
      hash() {
        return this.name.replace(/\s+/g, '-').toLowerCase();
      },
      header() {
        if (this.htmlContent) {
          return this.htmlContent;
        }
        return this.name;
      },
    },
  };
</script>
