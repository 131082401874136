import SAVE_AI_FEATURE_REQUEST from '@/graphql/SaveAiFeatureRequest.gql';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { get } from 'lodash-es';

export default {
  data: () => ({
    isAiEarlyAccessLoading: false,
  }),
  computed: {
    ...mapState(['account']),
    ...mapGetters(['userProfileDoneValue']),
    isOnboardingCompleted() {
      return !!this.userProfileDoneValue;
    },
  },
  methods: {
    ...mapMutations(['setProfileKey']),
    ...mapActions(['fetchAccount']),
    async saveAiEarlyAccessRequest(feature) {
      const key = `aiRequest_${feature}`;
      const hasRequestedAiFeature = get(this.account, `profile.${key}`, false);
      if (hasRequestedAiFeature) {
        this.openSuccessModal();
        return;
      }
      this.isAiEarlyAccessLoading = true;
      await this.$apollo.mutate({
        mutation: SAVE_AI_FEATURE_REQUEST,
        variables: { feature },
      });
      this.isAiEarlyAccessLoading = false;
      this.setProfileKey({ key, value: true });
      this.fetchAccount();
      this.openSuccessModal();
    },
    openSuccessModal() {
      this.$modal.show('early-access-subscription-success');
    },
  },
};
